import { Router } from "@reach/router"
import React, { useEffect } from "react"
import Header from "../browser/components/Header"
import Footer from "../universal/components/Footer"
import ArticlePage from "./article"
import AboutUs from "./about-us"
import Blog from "./blog"
import BlogPost from "./blogPost"
import InsurancePage from "./insurance"
import ContactPage from "./contact"
import Cookies from "../universal/components/Cookies"
import LandingPageContent from "../views/LandingPageContent"
import Helmet from "react-helmet"
import "../components/layout.scss"
import { showCookieBanner } from "../helpers/functions"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Dalmatiko osiguranje | Ekskluzivna agencija Allianz osiguranje
        </title>
        <link rel="canonical" href="https://dalmatiko-osiguranje.hr/" />
        <meta
          name="description"
          content="Dalmatiko osiguranje. Obavezno auto osiguranje. Dopunsko osiguranje. Životno osiguranje. Kasko osiguranje. Dodatno zdravstveno osiguranje."
        />
      </Helmet>
      <Header />
      <Router>
        <LandingPageContent path="/" />
        <ArticlePage path="/article/:id" />
        <AboutUs path="/about-us" />
        <BlogPost path="/blog/:id" />
        <Blog path="/blog" />
        <InsurancePage path="/insurance/:id" />
        <ContactPage path="/contact" />
      </Router>
      {showCookieBanner() ? <Cookies /> : null}
      <Footer />
    </>
  )
}

export default IndexPage
