import React, { useEffect, useState } from "react"
import "./index.scss"
import LandingCards from "../LandingCards"
import SliderLandingCards from "../../../browserMobile/components/SliderLandingCards"
import { connect } from "react-redux"

const LandingInsuranceTypes = ({ landing_offer_text }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className="landing-insurance-types">
      <div className="landing-insurance-types-title-desc-holder">
        <h2 className="landing-insurance-types-title">
          {landing_offer_text?.Naslov}
        </h2>

        <p className="landing-insurance-types-desc">
          {landing_offer_text?.Opis}
        </p>
      </div>

      {windowWidth >= 1025 ? <LandingCards /> : <SliderLandingCards />}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    landing_offer_text: state.strapiReducer.landingData?.Landing_Ponuda,
  }
}

export default connect(mapStateToProps, null)(LandingInsuranceTypes)
