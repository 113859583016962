import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import "./index.scss"
import Reveal from "react-reveal/Reveal"
import { Link } from "gatsby"

import { connect } from "react-redux"

const LandingBigCard = ({ landing_main }) => {
  let settings = {
    dots: true,
    dotsClass: "slick-dots dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: "slides",
    focusOnSelect: false,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    pauseOnHover: false,
  }

  return (
    <Reveal>
      <div className="landing-big-card">
        <Slider {...settings}>
          {landing_main?.map((main, i) => (
            <div key={i} className="landing-big-card-background">
              <img
                src={`https://api.trebamosiguranje.hr${main?.Slika?.url}`}
                alt={main?.Slika?.name}
                className="landing-big-card-img"
                onLoad={e => e.target.classList.add("loaded")}
              />
              <div className="landing-big-card-slide">
                <h2 className="landing-big-card-title">{main?.Naslov}</h2>
              </div>
              <a
                href={main?.Link}
                target="_blank"
                className="landing-big-card-see-more-btn"
              >
                Vidi više
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </Reveal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    landing_main: state.strapiReducer.landingData?.Landing_Glavno,
  }
}

export default connect(mapStateToProps, null)(LandingBigCard)
