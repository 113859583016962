import React, { useEffect, useState } from "react"
import LandingBigCard from "../../universal/components/LandingBigCard"
import LandingIcons from "../../universal/components/LandingIcons"
import LandingTextContent from "../../universal/components/LandingTextContent"
import LandingInsuranceTypes from "../../universal/components/LandingInsuranceTypes"
import "./index.scss"
import ConclusionInsurance from "../../universal/components/ConclusionInsurance"
import BlogPostRelatedArticles from "../../browser/components/BlogPostRelatedArticles"
import InfoContainer from "../../universal/components/InfoContainer"
import LandingFaq from "../../universal/components/LandingFaq"
import SliderLandingIcons from "../../browserMobile/components/SliderLandingIcons"
import strapi from "../../api/strapi"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getBlogs, getLandingData } from "../../redux/actions/strapiActions"
import "../root_anims.scss"

const LandingPageContent = ({ getLandingData, getBlogs, blogsList }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const responseLandingPage = await strapi.get("/landing")

      if (responseLandingPage.status === 200) {
        getLandingData(responseLandingPage.data)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePageData = await strapi.get("/blogs")
        console.log("RESPONSE: ", responsePageData)
        getBlogs(responsePageData.data)
      } catch (err) {
        console.log(err.message)
      }
    }
    fetchData()
  }, [getBlogs])

  return (
    <div className="landing-page-content-holder">
      <LandingBigCard />
      {windowWidth >= 1025 ? <LandingIcons /> : <SliderLandingIcons />}
      <LandingTextContent />
      <LandingInsuranceTypes />
      <ConclusionInsurance />
      {blogsList?.length ? <BlogPostRelatedArticles /> : null}
      <LandingFaq />
      <InfoContainer />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    blogsList: state.strapiReducer.blogsList,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLandingData: bindActionCreators(getLandingData, dispatch),
    getBlogs: bindActionCreators(getBlogs, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageContent)
