import React from "react"
import "./index.scss"
import car from "../../../assets/car.png"
import {
  DigitalInsurance,
  Dalmatia,
  TheBest,
  Calendar,
} from "../../../assets/icons"

const LandingIcons = () => {
  return (
    <div className="landing-icons">
      <div className="landing-icons-holder">
        <DigitalInsurance />
        <h3 className="landing-icons-title">Digitalno osiguranje</h3>
      </div>
      <div className="landing-icons-holder">
        <Dalmatia />
        <h3 className="landing-icons-title">
          Prisutni u cijeloj dalmaciji i šire
        </h3>
      </div>
      <div className="landing-icons-holder">
        <TheBest />
        <h3 className="landing-icons-title">Najbolji proizvodi na tržištu</h3>
      </div>
      <div className="landing-icons-holder">
        <Calendar />
        <h3 className="landing-icons-title">Isplata šteta u par dana</h3>
      </div>
    </div>
  )
}

export default LandingIcons
