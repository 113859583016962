import React, { createRef, useEffect } from "react"
import { BackArrowIcon } from "../../../../assets/icons"
import { gaOtherClickEvent } from "../../../../helpers/functions/googleAnalytics"

const LandingQuestionItem = ({ question, index, dropdowns, setDropdowns }) => {
  const dropdownContentRef = createRef()
  useEffect(() => {}, [])

  const openDropdown = (ref, state, index, stateFunc) => {
    if (state[index]) {
      ref.current.style.height = "0px"
      let newobj = {}
      Object.keys(state).forEach(ind => {
        newobj[ind] = false
      })
      stateFunc(newobj)
    } else {
      ref.current.style.height = ref.current.scrollHeight + 19 + "px"
      let newobj = {}
      Object.keys(state).forEach(ind => {
        newobj[ind] = false
      })
      stateFunc({ ...newobj, [index]: true })
    }
  }

  return (
    <div key={question?.id} className={"landing-question-answer-holder"}>
      <div
        className={"landing-question"}
        onClick={() => {
          gaOtherClickEvent("Klik na pitanje sa pocetne stranice")
          openDropdown(dropdownContentRef, dropdowns, index, setDropdowns)
        }}
      >
        <h3 className="landing-question-title">{question?.Naslov}</h3>
        <BackArrowIcon
          className={`landing-arrow  ${dropdowns[index] ? "open" : ""}`}
        />
      </div>

      <p
        ref={dropdownContentRef}
        style={
          dropdowns[index]
            ? {
                height: dropdownContentRef?.current?.scrollHeight + "px",
              }
            : { height: "0px" }
        }
        className={`landing-answer ${dropdowns[index] ? "open" : ""}`}
      >
        {question?.Opis}
      </p>
    </div>
  )
}

export default LandingQuestionItem
