import React from "react"
import "./index.scss"
import { connect } from "react-redux"

const LandingTextContent = ({ landing_text_content }) => {
  return (
    <div className="landing-text-content">
      <div className="landing-text-content-title-desc-holder">
        <h2 className="landing-text-content-title">
          {landing_text_content?.Naslov}
        </h2>

        <p className="landing-text-content-desc">
          {landing_text_content?.Opis}
        </p>
      </div>

      <div className="landing-text-content-img-holder">
        <img
          className="landing-text-content-img"
          src={`https://api.trebamosiguranje.hr${landing_text_content?.Slika?.url}`}
          alt={landing_text_content?.Slika?.name}
          onLoad={e => e.target.classList.add("loaded")}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    landing_text_content: state.strapiReducer.landingData?.Landing_Opis,
  }
}

export default connect(mapStateToProps)(LandingTextContent)
