import React, { useEffect, useState } from "react"
import "./index.scss"

import { connect } from "react-redux"
import LandingQuestionItem from "./LandingQuestionItem"

const LandingQuestion = ({ landing_question }) => {
  const [dropdowns, setDropdowns] = useState(false)

  useEffect(() => {
    let dropdownsNew = {}

    for (let i = 0; i < landing_question?.length; i++) {
      dropdownsNew = {
        ...dropdownsNew,
        [i]: false,
      }
    }
    setDropdowns(dropdownsNew)
  }, [])

  return (
    <>
      {landing_question?.map((question, i) => {
        return (
          <LandingQuestionItem
            key={i}
            index={i}
            question={question}
            dropdowns={dropdowns}
            setDropdowns={setDropdowns}
          />
        )
      })}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    landing_question: state.strapiReducer.landingData?.Landing_Pitanja?.Pitanje,
  }
}

export default connect(mapStateToProps, null)(LandingQuestion)
