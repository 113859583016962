import React, { useState, useEffect, useRef, createRef } from "react"
import "./index.scss"
import { PlusIconWhite, PlusIconPurple } from "../../../assets/icons"
import { Link } from "gatsby"

import { connect } from "react-redux"
import { gaInsuranceClickEvent } from "../../../helpers/functions/googleAnalytics"

const LandingCards = ({ landing_card }) => {
  const [windowWidth, setWindowWidth] = useState(0)
  const [isHover, setHover] = useState(false)
  const [isClick, setClick] = useState(false)
  const [id, setId] = useState(null)
  const [cards, setCards] = useState(false)
  const testRef = useRef()

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  const handleClickWindow = e => {
    if (testRef.current && !testRef.current.contains(e.target)) {
      let index = parseInt(testRef.current.id)
      setId(null)
      setClick(false)
      setCards({ ...cards, [index]: false })
      testRef.current = null
    }
  }

  useEffect(() => {
    let cards = {}

    for (let i = 0; i < landing_card?.length; i++) {
      cards = {
        ...cards,
        [i]: false,
      }
    }

    setCards(cards)
  }, [])

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    window.addEventListener("click", handleClickWindow)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("click", handleClickWindow)
    }
  }, [])

  const handleOnHover = id => {
    setId(id)
    setHover(true)
  }

  const handleOffHover = () => {
    setId(null)
    setHover(false)
  }

  const handleOnClick = (id, state, index, stateFunc) => {
    if (state[index]) {
      let newobj = {}
      Object.keys(state).forEach(ind => {
        newobj[ind] = false
      })
      stateFunc(newobj)
    } else {
      let newobj = {}
      Object.keys(state).forEach(ind => {
        newobj[ind] = false
      })
      stateFunc({ ...newobj, [index]: true })
      setClick(true)
      setId(id)
    }
  }

  return (
    <div className="landing-cards">
      {landing_card?.map((category, index) => {
        return (
          <div
            ref={isClick && id === category.id ? testRef : null}
            className="landing-cards-flip-card"
            id={index}
            key={index}
            onMouseEnter={
              windowWidth > 1024 ? () => handleOnHover(category.id) : null
            }
            onMouseLeave={windowWidth > 1024 ? () => handleOffHover() : null}
            onClick={() =>
              windowWidth < 1025 &&
              handleOnClick(category?.id, cards, index, setCards)
            }
          >
            <div
              className={`landing-cards-card ${
                (isHover && id === category.id) || (cards[index] && isClick)
                  ? "flip"
                  : ""
              }`}
            >
              <figure className="landing-cards-front">
                <img
                  src={`https://api.trebamosiguranje.hr${category?.Slika?.url}`}
                  alt={category?.Slika?.name}
                  className="landing-cards-img"
                  onLoad={e => e.target.classList.add("loaded")}
                />

                <h3 className="landing-cards-title">{category?.Ime2}</h3>

                <PlusIconWhite className="landing-cards-btn" />
              </figure>

              <figure className="landing-cards-back">
                <div className="landing-cards-img-2-holder">
                  <img
                    src={`https://api.trebamosiguranje.hr${category?.Slika2?.url}`}
                    alt={category?.Slika2?.name}
                    className="landing-cards-img-2 car heart house suitcase"
                    onLoad={e => e.target.classList.add("loaded")}
                  />
                </div>
                <div className="landing-cards-title-2-holder">
                  <h3 className="landing-cards-title-2">{category?.Ime2}</h3>
                </div>
                <div className="landing-cards-links-holder">
                  {category?.Proizvodi?.map((product, i) => (
                    <Link
                      key={i}
                      to={`/insurance/${product?.Slug}`}
                      className="landing-cards-link"
                      onClick={() =>
                        gaInsuranceClickEvent(
                          `Klik na osiguranje (${product?.Slug}) sa kartice`
                        )
                      }
                    >
                      {product?.Ime}
                    </Link>
                  ))}
                </div>

                <PlusIconPurple className="landing-cards-btn flip" />
              </figure>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    landing_card: state.strapiReducer.productCategory,
  }
}

export default connect(mapStateToProps, null)(LandingCards)
