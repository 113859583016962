import React from "react"
import "./index.scss"
import LandingFaqForm from "../LandingFaqForm"

import { connect } from "react-redux"

const LandingFaq = ({ landing_faq_title, landing_faq_title2 }) => {
  return (
    <div className="landing-faq">
      <div className="landing-faq-titles-holder">
        <h4 className="landing-faq-title">{landing_faq_title}</h4>

        <h2 className="landing-faq-title-2">{landing_faq_title2}</h2>
      </div>

      <LandingFaqForm />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    landing_faq_title: state.strapiReducer.landingData?.Landing_Pitanja?.Naslov,
    landing_faq_title2:
      state.strapiReducer.landingData?.Landing_Pitanja?.Naslov2,
  }
}

export default connect(mapStateToProps, null)(LandingFaq)
