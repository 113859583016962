import React from "react"
import LandingCards from "../../../universal/components/LandingCards"
import "./index.scss"

const SliderLandingCards = () => {
  return (
    <div className="slider-landing-cards">
      <div className="slider-landing-cards-items">
        <LandingCards />
      </div>
    </div>
  )
}

export default SliderLandingCards
