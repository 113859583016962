import React from "react"
import "./index.scss"
import LandingQuestion from "../LandingQuestion"

const LandingFaqForm = () => {
  return (
    <div className="landing-faq-form">
      <LandingQuestion />
    </div>
  )
}

export default LandingFaqForm
