import React from "react"
import LandingIcons from "../../../universal/components/LandingIcons"
import "./index.scss"

const SliderLandingIcons = () => {
  return (
    <div className="slider-landing-icons">
      <div className="slider-landing-icons-items">
        <LandingIcons />
      </div>
    </div>
  )
}

export default SliderLandingIcons
